<template>
    <div id="addCollection">
        
        <div class="header">
            <h4>添加备忘信息</h4>
            <!-- <i class="iconfont icon-guanbi" @click="noCollection"></i> -->
        </div>
        
        <div class="overHide">
            <div class="box">
                <div class="box_left" ref="boxLeft">
                    <div ref="select" class="select" v-for="item in remarkNum" :key="item">
                        <div  class="selectBtn">
                            <button @click.stop="onSelect($event)">选择 <i class="iconfont icon-dianji"></i></button>
                            <ul>
                                <li v-for="(item, index) in remarkTypesData" :key="index" @click="selectRemarkTypes(item.id, index, $event)">{{item.cheatLabel}}</li>
                            </ul>
                        </div>
                        <div>
                            <input type="text" placeholder="内容">
                        </div>
                    </div>
                </div>
                <div class="box_right"><i class="iconfont icon-shanchu1 del" ref="del"  @click="delRemark"></i></div>
            </div>

            <div class="remark">
                <ul v-if="cheatPhrases.length>0">
                    <li v-if="item" v-for="(item, index) in cheatPhrases" :key="index" @click="selectRemark($event)">{{item}} <i class="iconfont icon-jia"></i></li>
                </ul>
            </div>
            <div class="add">
                <i class="iconfont icon-jia" @click="addRemark"></i>
            </div>
        </div>


        <div class="footer">
            <button class="confirm" @click="collecion(1)">保存备忘</button>
            <button class="cancel" @click="collecion(2)">取消修改</button>
        </div>
    </div>
</template>

<script>
import {getRemarkTypes, addFav} from '../api/index'
export default {
    props:['collectionModal', 'productId', 'productIndex', 'isAddRemark'],
    data () {
        return {
            token:'',
            remarkTypesData: '', //备忘分类
            cheatPhrases: '', //每个分类下的备忘
            remarkNum: 1,
            collectionData: {
                token:'',
                id: this.productId,
                cheats:[]
            }
        }
    },
    mounted () {
        const {token} = this.$route.query;
        this.token = token;
        this.collectionData.token = token;
        this.remarkTypes();
    },
    methods: {

        //点击选择备忘按钮交互操作
        onSelect (e) {
            let target = '';
            e.target.tagName == 'BUTTON' ? target = e.target : target = e.target.parentNode;
            let isName = target.children[0].className.indexOf('roate');
            const {select} = this.$refs;
            if (isName == -1) {
                select.map(data => {
                    data.children[0].children[0].children[0].className = 'iconfont icon-dianji';
                    data.children[0].children[1].style.display = 'none';
                });
                target.children[0].className = 'iconfont icon-dianji roate';
                target.nextSibling.style.display = 'block';
            } else {
                target.children[0].className = 'iconfont icon-dianji';
                target.nextSibling.style.display = 'none';
            }
        },

        //添加备忘
        addRemark () {
            const {boxLeft} = this.$refs;
            this.remarkNum++; 
            this.cheatPhrases='';
            this.$refs.del.style.marginTop = `${this.$refs.boxLeft.clientHeight+10}px`;
        },

        //删除备忘
        delRemark () {
            const {boxLeft} = this.$refs;
            const {remarkTypesData} = this;
            if (this.remarkNum > 1) {
                this.remarkNum--;
                //删除一项备忘重新计算高度更改删除按钮位置
                let select = document.getElementsByClassName('select');
                this.remarkNum > 1 ?
                this.$refs.del.style.marginTop = `${select[select.length-2].offsetTop - select[select.length-2].clientHeight -10}px` :
                this.$refs.del.style.marginTop = `6px`;
    
                //删除一项备忘重新获取最后一项备忘下分类中话术，更新显示内容
                let labelId = select[this.remarkNum-1].children[0].children[0].dataset.labelId;
                if (labelId) {
                    remarkTypesData.map(data => {
                        data.id == labelId && (this.cheatPhrases = data.cheatPhrases.split(','));
                    })
                }
            }

            

        },

        //选择备忘分类
        selectRemarkTypes (id, index, e) {
            let target = e.target;
            target.parentNode.previousSibling.innerHTML = `${target.innerText} <i class="iconfont icon-dianji"></i>`;
            target.parentNode.previousSibling.dataset.labelId = id;
            target.parentNode.style.display = 'none';
            this.cheatPhrases = this.remarkTypesData[index].cheatPhrases.split(',');
        },

        //选择备忘
        selectRemark (e) {
            let target = '';
            if (e.target.tagName == 'LI') {
                target = e.target;
            } else {
                target = e.target.parentNode;
            }
            // console.log(this.$refs.select[this.$refs.select.length-1])
            let input = this.$refs.select[this.$refs.select.length-1].childNodes[1].children[0];
            input.value.length > 0 ? input.value += `，${target.innerText}` : input.value += target.innerText;

        },

        async remarkTypes () {
            const {token} = this;
            let res = await getRemarkTypes(token);
            this.remarkTypesData = res.data.list;
        },

        restTypes () {
            const {select} = this.$refs;
            this.remarkNum = 1;
            this.$refs.del.style.marginTop = `6px`;
            this.$refs.select[0].children[0].children[0].innerHTML = '选择 <i class="iconfont icon-dianji"></i>';
            this.$refs.select[0].children[0].children[1].style.display = 'none';
            this.$refs.select[0].children[1].children[0].value = '';
            this.cheatPhrases =  ''; //每个分类下的备忘
        },

        //收藏
        async collecion (num) {
            const {select} = this.$refs;           
            var cheats = [];
             if (num == 1) {  //点击确认时收藏
                select.map(data => {
                    var labelId = data.children[0].children[0].dataset.labelId;
                    var value = data.children[1].children[0].value;
                    if (labelId) {
                        var arr = {
                            labelId: labelId,
                            contact:value
                        }
                        cheats.push(arr);
                    }
                })
                this.collectionData.cheats = cheats;
            }
            this.collectionData.id = this.productId;
            if (this.isAddRemark) {
               num == 1 ? this.$emit('confirmAddRemark',{model:false, cheats:this.collectionData.cheats}) : this.noCollection();
            } else {
                let res = await addFav(this.collectionData);
                console.log(res);
                if (res.status == 0) {
                    this.$emit('confirmCollection', {model: false, productIndex: this.productIndex, isFav:true});
                    select[0].children[0].children[0].innerHTML = '选择 <i class="iconfont icon-dianji"></i>';
                    select[0].children[0].children[0].dataset.labelId = '';
                    select[0].children[1].children[0].value = '';
                    this.cheatPhrases = '';
                    this.remarkNum = 1;
                    this.$toast('收藏成功');
                }
            }
        },

        noCollection () {
            this.$emit('confirmCollection', {model: false, productIndex:'', isFav:false})
        }
    }
}
</script>

<style lang="less" src="@/style/addCollection.less">

</style>